<template>
  <div class="flex flex-col gap-4">
    <div class="w-full flex flex-row justify-between gap-3">
      <h1 class="text-2xl font-bold" v-html="title"></h1>
      <Button
        v-if="closeButton"
        theme="link"
        class="-my-1"
        @click="$emit('close')"
      >
        <font-awesome-icon icon="fa-light fa-xmark" size="lg" />
      </Button>
    </div>
    <p v-html="description"></p>

    <div ref="otpCont" class="flex flex-row items-center justify-between gap-2">
      <input
        v-for="(digit, index) in modelValue"
        :key="index"
        v-model="modelValue[index]"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        maxlength="1"
        @paste="handlePaste($event)"
        class="input-box w-full text-center rounded-lg text-base md:text-xl border-2 border-gray-200 bg-transparent px-5 py-3 !px-1 md:px-3 dark:border-gray-800"
        :autofocus="index === 0"
        @input="handleInput(index)"
        @keydown="handleKeyDown($event, index)"
      />
    </div>

    <p v-if="errors" class="text-red-500 dark:text-red-400 text-sm">
      Error: {{ errors }}
    </p>

    <div class="flex flex-row items-center justify-between pb-4">
      <div>
        <p>Didn't get the code?</p>
        <p class="text-sm italic">Code expires after 5 minutes.</p>
      </div>
      <Button theme="link" @click="emit('resend')">Send code to email</Button>
    </div>

    <p class="text-sm mt-4 text-cool-400 dark:text-cool-600">
      Emails can be delivered to more than inbox and spam folders. Be sure to
      use your email's search functionality to search for "favoritely.com". If
      you are having trouble, contact
      <a href="mailto:support@favoritely.com">support@favoritely.com</a>
    </p>
    <div class="flex flex-row gap-2 justify-center py-8">
      <span>Need help?</span>
      <a href="mailto:support@favoritely.com">Contact Support</a>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from "vue"

  const props = defineProps({
    modelValue: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    errors: {
      type: String,
      default: null,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(["close", "update:modelValue", "resend", "submit"])

  const otpCont = ref(null)

  const digitCount = computed(() => {
    return props.modelValue.length
  })

  const loginReady = computed(() => {
    return props.modelValue.filter(Boolean).length === digitCount.value
  })

  const handleInput = (index) => {
    const digit = props.modelValue[index]
    if (digit && digit.length > 1) {
      props.modelValue[index] = digit.slice(0, 1)
    }
    emit("update:modelValue", props.modelValue)

    if (digit && index < digitCount.value - 1) {
      otpCont.value.children[index + 1].focus()
    } else if (digit && index === digitCount.value - 1) {
      // Auto-submit when the last digit is entered
      if (loginReady.value) {
        emit("submit")
      }
    }
  }

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !props.modelValue[index] && index > 0) {
      props.modelValue[index - 1] = ""
      otpCont.value.children[index - 1].focus()
      emit("update:modelValue", props.modelValue)
    } else if (event.key === "ArrowLeft" && index > 0) {
      otpCont.value.children[index - 1].focus()
    } else if (event.key === "ArrowRight" && index < digitCount.value - 1) {
      otpCont.value.children[index + 1].focus()
    }
  }

  const handlePaste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData("text")
    const pasteArray = paste.split("").filter((char) => /\d/.test(char))

    pasteArray.forEach((char, index) => {
      if (index < digitCount.value) {
        props.modelValue[index] = char
      }
    })

    emit("update:modelValue", props.modelValue)

    // Auto-submit if all digits are filled after paste
    if (loginReady.value) {
      emit("submit")
    }

    event.preventDefault()
  }
</script>

<style lang="scss" scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
